export default [
  {
    name: 'Rodrigo Nascimento',
    occ: 'CEO e Head de Criação',
    picp: 'Rodrigo',
  },
  {
    name: 'Francine Wartchow',
    occ: 'Gerente de Operações e Produção',
    picp: 'Francine'
  },
  {
    name: 'Tatiana Cruz',
    occ: 'Head Conteúdo e Marca',
    picp: 'Tatiana'
  },
  {
    name: 'Cristiane Marçal',
    occ: 'Coordenadora de Projetos',
    picp: 'Cristiane'
  },
  {
    name: 'Roger Lerina',
    occ: 'Conteúdo',
    picp: 'Roger'
  },
  {
    name: 'Gustavo Foster',
    occ: 'Conteúdo',
    picp: 'Gustavo'
  },
  {
    name: 'Caroline Soares',
    occ: 'Design',
    picp: 'Carol'
  },
  {
    name: 'Rafael Boardmann',
    occ: 'Design',
    picp: 'Rafael'
  },
  {
    name: 'Cássio Silva',
    occ: 'Arquitetura',
    picp: 'Cássio'
  },
  {
    name: 'Fernando Goularte',
    occ: 'Arquitetura',
    picp: 'Fernando'
  },
  {
    name: 'Luiz Stroff',
    occ: 'Marketing',
    picp: 'Luiz'
  },
  {
    name: 'Katerine Scholles',
    occ: 'Produção',
    picp: 'Katerine'
  },
  {
    name: 'Alexandre Fachel',
    occ: 'Produção',
    picp: 'Alexandre'
  },
  {
    name: 'Érika Vaz',
    occ: 'Administrativo e Financeiro',
    picp: 'Erika'
  }
]