






































import { Component, Vue } from "vue-property-decorator";
import projetos from '@/assets/content/projetos';
import tags from '@/assets/content/tags';
import Gallery from '@/components/Gallery.vue';
import fwheel from '../components/svg/fourthwheel.vue';
import hwheel from '../components/svg/halfwheel.vue';
import organic from '../components/svg/organic.vue';
import waves from '../components/svg/waves.vue';

@Component({
  components: {
    gallery: Gallery,
    fwheel,
    hwheel,
    organic,
    waves
  },
})
export default class Projetos extends Vue {
  private projects = projetos;

  private get name() {
    return this.$route.params.name;
  }

  private get project() {
    return this.projects.find(i => i.prefix == this.name);
  }

  private get contents() {
    const items = [];
    for (let i = 0; i < (this.project?.gallery ?? 0); i++) {
      items.push(`${this.project?.prefix}_${i+1}`);
    }
    return items;
  }

  private get tags() {
    return Object.values(tags).filter(t => {
      const i = Object.values(tags).indexOf(t);
      return this.project?.tags.includes(i);
    })
  }

  private get sns() {
    return this.project?.sn;
  }

  private get themeColors() {
    if (this.project?.theme == 'dark') {
      return {
        fw1: 'blue',
        org1: 'dark-blue',
        org2: 'pink',
        fw2: 'pink',
        hw1: 'dark-blue',
        org3: 'pink',
        wvs1: 'dark-blue'
      }
    }
    return {
      fw1: 'teal',
      org1: 'orange',
      org2: 'orange',
      fw2: 'blue',
      hw1: 'dark-blue',
      org3: 'teal',
      wvs1: 'dark-blue'
    }
  }

  private get tagsList() {
    return this.$refs.tags as HTMLElement;
  }

  private windowWidth = 0;

  private updateWindowWidth() {
    this.windowWidth = window.innerWidth;
  }

  mounted() {
    setTimeout(() => {
      document.title = `Rompecabezas — ${this.project?.nome}`;
      if (this.project?.theme == 'dark') {
        this.tagsList.style.setProperty('--theme_color', 'rgba(228, 161, 226, .5)');
      } else if (this.project?.theme == 'light') {
        this.tagsList.style.setProperty('--theme_color', 'rgba(240, 144, 105, .5)');
      } else {
        this.tagsList.style.setProperty('--theme_color', 'rgba(96, 213, 250, .5)');
      }
    }, 1);
    window.removeEventListener('resize', this.updateWindowWidth);
    window.addEventListener('resize', this.updateWindowWidth);
    this.updateWindowWidth();
  }
}
