









import { Vue, Component } from "vue-property-decorator";
import flow from '../assets/content/flow';

@Component({})
export default class FlowExperienceStandalone extends Vue {
  private get hovers() {
    return flow.hovers
  }
}
