























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Gallery extends Vue {
  @Prop({ required: true }) contents!: Array<string>;

  private storageURL = 'https://rompecabezas.azureedge.net/files/rompecabezas/projects/gallery/';

  private get view() {
    return this.$refs.view as HTMLElement;
  }

  private get scroller() {
    return this.$refs.scroller as HTMLElement;
  }

  private get indicator() {
    return this.$refs.indicator as HTMLElement;
  }

  private fscreenSwitch = false;

  private getContent(content: string) {
    return `${this.storageURL}${content}.jpg`;
  }

  private fullView() {
    this.view.classList.toggle('full-view');
    this.fscreenSwitch = !this.fscreenSwitch;
  }

  private scrollBy(pass = true) {
    const windowWidth = window.innerWidth;
    this.scroller.scrollBy({
      left: pass ? windowWidth : -windowWidth,
      behavior: 'smooth'
    })
  }

  private scrollTo(index: number) {
    const windowWidth = window.innerWidth;
    this.scroller.scrollTo({
      behavior: "smooth",
      left: index * windowWidth
    })
  }

  private updateIndicator() {
    let index = 0;
    for(let i = 0; i < this.scroller.children.length; i++) {
      const closest = (this.scroller.children.item(index) as HTMLElement).getBoundingClientRect();
      const item = (this.scroller.children.item(i) as HTMLElement).getBoundingClientRect();
      if (Math.abs(item.x) < Math.abs(closest.x)) {
        index = i;
      }
    }
    const first = this.indicator.children.item(index) as HTMLElement;
    const current = document.getElementsByClassName('current-indicator')[0];
    current?.classList.toggle('current-indicator');
    first.classList.toggle('current-indicator');
  }
}
