

















interface AccordionPanel {
  id: number;
  panel: HTMLElement;
}

import { Vue, Component } from "vue-property-decorator";

import flow from '../assets/content/flow';

@Component({})
export default class FlowContentMobile extends Vue {

  private previousPanel!: AccordionPanel
  private accordionState: Array<boolean> = []

  private get toc() {
    return flow.toc
  }

  mounted() {
    for (let index = 0; index < this.toc.length; index++) {
      Vue.set(this.accordionState, index, false);
    }
  }
  
  private clickToggle(index: number, event: MouseEvent) {
    const target = event.target as HTMLElement

    const accordionPanel = (target.closest('.accordion-toggle') as HTMLElement).nextSibling as HTMLElement;

    if(this.previousPanel) {
      if(this.accordionState[this.previousPanel.id] && this.previousPanel.id !== index) {
        this.toggle(this.previousPanel.id, this.previousPanel.panel)
      }
    }
    
    this.toggle(index, accordionPanel)

    this.previousPanel = { id: index, panel: accordionPanel}
  }

  private toggle(index: number, panel: HTMLElement, forceClose = false) {
    if ((panel.style.maxHeight == '0px' || panel.style.maxHeight == '') && !forceClose) {
      panel.style.maxHeight = panel.scrollHeight + "px";

      Vue.set(this.accordionState, index, true);
    } else {
      panel.style.maxHeight = '0px';

      Vue.set(this.accordionState, index, false);
    }
  }
}

