







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PuzzlePieceShape extends Vue {
  @Prop({ default: 'white' }) color!: string;
}
