export default [
  {
    nome: 'Noite dos Museus',
    prefix: 'noite-dos-museus',
    tags: [0, 1, 2, 3],
    title: 'Noite dos Museus',
    intro: 'Arte, música, espaços públicos e novas perspectivas',
    gallery: 27,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/537691313?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {
      fb: 'https://www.facebook.com/noitedosmuseus',
      ig: 'https://www.instagram.com/noitedosmuseus',
      tt: 'https://twitter.com/noitedosmuseus'
    },
    theme: 'light'
  },
  {
    nome: 'Wineyard',
    prefix: 'wineyard',
    tags: [4, 1, 2, 3, 5],
    title: 'Wineyard',
    intro: 'Uma exposição sensorial transformadora',
    gallery: 14,
    video:'<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/359370092?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {
      fb: 'https://www.facebook.com/Wineyard-530363977426304',
      ig: 'https://www.instagram.com/wineyard_'
    },
    theme: 'light'
  },
  {
    nome: 'Animaliê',
    prefix: 'animalie',
    tags: [4, 0, 2],
    title: 'Animaliê',
    intro: 'Uma imersão no fascinante mundo selvagem',
    gallery: 12,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/536947033?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {
      fb: 'https://www.facebook.com/animalieexperience',
      ig: 'https://www.instagram.com/animalieexperience'
    },
    theme: 'light'
  },
  {
    nome: 'Roasterie',
    prefix: 'roasterie',
    tags: [4, 0, 1, 2, 7, 5],
    title: 'Roasterie',
    intro: 'Um café, cinco sentidos',
    gallery: 7,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/465496297?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {
      ig: 'https://www.instagram.com/roasterie.coffee'
    },
    theme: 'light'
  },
  {
    nome: 'Arena Experimental e Interativa',
    prefix: 'arena-popup',
    tags: [6, 2, 7, 5, 8],
    title: 'Arena Experimental e Interativa',
    intro: '',
    gallery: 13,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/522931215?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {},
    theme: 'dark'
  },
  {
    nome: 'Craft Brew Experience',
    prefix: 'craft-brew-experience',
    tags: [4, 0, 1, 2, 7, 5],
    title: 'Craft Brew Experience',
    intro: 'O universo e mistérios da cerveja',
    gallery: 9,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/466320483?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {},
    theme: 'light'
  },
  {
    nome: 'Wineyard Food & Wine Experience',
    prefix: 'wineyard-experience',
    tags: [0, 1, 2],
    title: 'Wineyard Food & Wine Experience',
    intro: 'Uma experiência sensorial no universo do vinho',
    gallery: 9,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/537401252?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {},
    theme: 'light'
  },
  {
    nome: 'Wellness',
    prefix: 'wellness',
    tags: [0, 1, 2],
    title: 'Wellness',
    intro: '',
    gallery: 8,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/444245827?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {},
    theme: 'light'
  },
  {
    nome: 'Brilha POA',
    prefix: 'brilha-poa',
    tags: [1, 6],
    title: 'Brilha POA',
    intro: 'Projeto realizado em Porto Alegre/RS',
    gallery: 14,
    sn: {},
    theme: 'dark'
  },
  {
    nome: 'Espaço Inovação',
    prefix: 'michelin',
    tags: [1, 5],
    title: 'Espaço Inovação e Criatividade para a unidade Michelin Campo Grande/RJ',
    intro: '',
    gallery: 4,
    sn: {},
    theme: 'dark'
  },
  {
    nome: 'Mundo Abissal',
    prefix: 'mundo-abissal',
    tags: [4, 2],
    title: 'Mundo Abissal',
    intro: 'As sensações de um mundo desconhecido',
    gallery: 7,
    sn: {},
    theme: 'dark'
  },
  {
    nome: 'Pepsi Twist Land',
    prefix: 'pepsi',
    tags: [6, 2],
    title: 'Pepsi Twist Land',
    intro: 'Festival realizado na Praia de Atlântida/RS',
    gallery: 16,
    sn: {},
    theme: 'dark'
  },
  {
    nome: 'Vinícola Toro Loco',
    prefix: 'toro-loco',
    tags: [6, 3],
    title: 'Mini DOC vinícola Toro Loco',
    intro: '',
    gallery: 7,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/365488706?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {},
    theme: 'video'
  },
  {
    nome: 'Vinícola Ventisquero',
    prefix: 'ventisquero',
    tags: [6, 3],
    title: 'Mini DOC vinícola Ventisquero',
    intro: '',
    gallery: 8,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/355071514?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {},
    theme: 'video'
  },
  {
    nome: 'Vinícola Cecchi',
    prefix: 'cecchi',
    tags: [6, 3],
    title: 'Mini DOC vinícola Cecchi',
    intro: '',
    gallery: 8,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/355099179?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {},
    theme: 'video'
  },
  {
    nome: 'Vinícola Boisset',
    prefix: 'boisset',
    tags: [6, 3],
    title: 'Mini DOC vinícola Boisset',
    intro: '',
    gallery: 3,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/369911646?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {},
    theme: 'video'
  },
  {
    nome: 'Vinícola Fantinel',
    prefix: 'fantinel',
    tags: [6, 3],
    title: 'Mini DOC vinícola Fantinel',
    intro: '',
    gallery: 7,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/362624648?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {},
    theme: 'video'
  },
  {
    nome: 'Vinícola VIK',
    prefix: 'vik',
    tags: [6, 3],
    title: 'Mini DOC vinícola VIK',
    intro: '',
    gallery: 12,
    video: '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/355083802?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>',
    sn: {},
    theme: 'video'
  },
  {
    nome: 'Ação Best Place to Kiss Freixenet',
    prefix: 'freixenet',
    tags: [6, 0, 9],
    title: 'Ação em comemoração ao Dia dos Namorados para a marca espanhola Freixenet',
    intro: '',
    gallery: 5,
    sn: {},
    theme: 'dark'
  },
  {
    nome: 'Julieta Venegas',
    prefix: 'julieta-venegas',
    tags: [6, 2],
    title: 'Turnê de Julieta Venegas no Brasil',
    intro: '',
    gallery: 12,
    sn: {},
    theme: 'dark'
  },
  {
    nome: 'Carla Bruni',
    prefix: 'carla-bruni',
    tags: [6, 2],
    title: 'Turnê de Carla Bruni no Brasil',
    intro: '',
    gallery: 10,
    sn: {},
    theme: 'dark'
  },
  {
    nome: 'Icona Pop',
    prefix: 'icona-pop',
    tags: [6, 2],
    title: 'Turnê de IconaPop no Brasil',
    intro: '',
    gallery: 4,
    sn: {},
    theme: 'dark'
  },
  {
    nome: 'Christina Perri',
    prefix: 'christina-perri',
    tags: [6, 2],
    title: 'Turnê de Christina Perri no Brasil',
    intro: '',
    gallery: 8,
    sn: {},
    theme: 'dark'
  },
  {
    nome: 'Cafe de Los Maestros',
    prefix: 'los-maestros',
    tags: [6, 2],
    title: 'Inauguração do Teatro Bradesco/SP com a apresentação da orquestra de tango Café de Los Maestros e participação de Marisa Monte e Gustavo Santaolalla',
    intro: '',
    gallery: 7,
    sn: {},
    theme: 'dark'
  },

]