



































import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Home extends Vue {
  private get nav() {
    return this.$refs.nav as HTMLElement;
  }

  private toggleMenu(close = false) {
    if (close) {
      this.nav.classList.remove("menu");
    } else {
      this.nav.classList.toggle("menu");
    }
  }

  private scrollTo(id: string) {
    const el = document.getElementById(id) as HTMLElement;
    setTimeout(() => {
      el?.scrollIntoView({ behavior: "smooth" });
    }, 1);
  }
}
