











import { Component, Vue } from "vue-property-decorator";

import flow from '../assets/content/flow';

@Component({})
export default class FlowContentStandalone extends Vue {
  
  private get toc() {
    return flow.toc
  } 

  private tocItemHover(event: MouseEvent) {
    const active = document.querySelector('.toc-item.active-item') as HTMLElement ?? null;

    if (active) {
      active.classList.remove('active-item');
      const activeContainer = active.querySelector('.toc-container') as HTMLElement;
      activeContainer.style.maxHeight = '0px';
    }

    const next = event.target as HTMLElement;
    next.classList.add('active-item');
    const nextContainer = next.querySelector('.toc-container') as HTMLElement;
    const nextContent = next.querySelector('.toc-content') as HTMLElement;
    nextContainer.style.maxHeight = `${nextContainer.getBoundingClientRect().height + nextContent.getBoundingClientRect().height}px`;
  }
}
