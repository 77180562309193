export default {
  toc: [
    {
      title: 'Content Intelligence',
      text: 'Curadoria de conteúdos baseados em insights coletados via data, respondendo em tempo real às interações com os consumidores de forma a gerar experiências cujos dados nos permitem um melhor entendimento comportamental capazes de estreitar o relacionamento com o público-alvo da marca.'
    },
    {
      title: 'Branded Content Strategy',
      text: 'Planejamento omnichannel e produção de conteúdos multiplataformas que colocam o shopper no centro da narrativa garantindo que a sinergia criativa seja mantida em todas as plataformas.'
    },
    {
      title: 'Community Manager',
      text: 'Construir, gerenciar, planejar e monitorar as comunidades da marca de forma a captar, fidelizar e impulsionar as vendas.'
    },
    {
      title: 'Branded Content Studio',
      text: 'Um ambiente de descobertas, um laboratório de ideias, criação, planejamento, distribuição, análise e automação de conteúdos. Web series, podcasts, artigos multimídia, música, fotografia, um espaço para criar histórias que conectem a marca com seu público-alvo, experiências multimídia que convidam o público a explorar produtos da marca e realizar a compra.'
    },
    {
      title: 'Influencer Network',
      text: 'Ativação da rede de micro-influencers em diferentes regiões do Brasil, conforme demanda, e estratégia de conteúdo e ações desenvolvidas para estreitar o relacionamento da marca com seu público-alvo.'
    }
  ],
  hovers: [
    {
      title: 'Brand Entertainment',
      text: 'Criação, desenvolvimento e produção de ações segmentadas que combinam o ambiente físico e o digital gerando insights que permitem mapear interações humanas complexas por meio do omni-relacionamento, racional e emocionalmente, para identificar e elevar a experiência geral do cliente com a categoria.'
    },
    {
      title: 'Brand & Retail<br>Design Experience',
      text: 'Concepção, projeção e construção de ambientes, espaços imersivos que priorizam a empatia e evocam emoções que resultam em experiências cujas narrativas conectam a brand voice a todo o ecossistema da marca - comunicação, produtos, serviços, pdv, mídias sociais, e-commerce e CRM.'
    },
    {
      title: 'Technology Retail',
      text: 'Transformar insights em realidade de negócios através de experiencias que conectem, inspirem, informem e impulsionem a categoria, do pdv ao e-commerce, apoiados por ferramentas inovadoras para mudar a maneira como os clientes se relacionam com a marca e seus produtos.'
    },
    {
      title: 'Brand Activation',
      text: 'Definir, projetar e executar comunicação de marca orientada para um propósito que vai além do puramente visceral para realmente compreender e ativar o DNA da marca como uma experiência 360º em todos os seus pontos de contatos, eventos B2B e B2C, lojas pop-up, etc.'
    },
    {
      title: 'Brand Immersive<br>Experiences',
      text: 'Criação de experiências digitais imersivas e intuitivas por meio de ambientes multitoque, instalações e aplicativos da web e mobile, reimaginando como pessoas, produtos e serviços podem ser reunidos, criando valor incremental por meio de uma experiência contínua.'
    }
  ],
  grid: [
    {
      color: 'orange',
      title: 'Landscape & opportunity assessment',
      text: 'Identificar oportunidades de crescimento de categoria e marca. Priorização de oportunidades. Novas oportunidades de distribuição. Priorização de público. Modelagem de crescimento de participação de categoria.'
    },
    {
      color: 'teal',
      title: 'Measurement, Optimization & Automation',
      text: 'Abordagens de modelagem avançada baseada em IA e Machine Learning para prever desempenho de engajamento de conteúdo, desempenho do público, desempenho de click-through, aumento de vendas e ROI.'
    },
    {
      color: 'pink',
      title: 'Development & Implementation',
      text: 'Criação de conceito e especificação técnica até o desenvolvimento de software, protótipo e teste, antes da integração no ecossistema de marca mais amplo, com relatórios de métricas e análises associadas para entender o comportamento do shopper e otimizar o ROI em tempo real, em várias plataformas.'
    },
    {
      color: 'blue',
      title: 'Reporting & Data Visualization',
      text: 'Democratizar dados para tomada de decisão estratégica. Apoio à decisão estratégica. Visualizações avançadas. Painel de controle personalizado. Acesso e comentários em tempo real.'
    },
    {
      color: 'pink',
      title: 'Transactional Segmentation',
      text: 'Com base no comércio, percepção e compreensão comportamental. Desenvolvimento da personalidade do comportamento de compra com base em frequência e dinheiro. Análise de transação Omnichannel. Desenvolvimento de esquema de direcionamento focado e quantificável.'
    },
  ],
  static: [
    {
      title: 'Sponsorship Management Advisory',
      text: 'Planejamento estratégico, implementação e suporte na construção da política de patrocínios e investimentos sociais, culturais e esportivos, incluindo gerenciamento, curadoria, avaliação de projetos, negociação e gestão de contrapartidas, acompanhamento e apresentação de relatórios de resultados.'
    },
    {
      title: 'Social, Cultural & Sports Sponsorship Consultancy',
      text: 'Estudo, planejamento e análise de investimento em iniciativas culturais, esportivas e sociais através das leis de incentivo fiscal atuando em todas as etapas do processo em conjunto com os setores jurídico, compliance, marketing e contábil da empresa.'
    },
    {
      title: 'Marketing & Cultural Program Strategy',
      text: 'Orientação estratégica, desenvolvimento e suporte técnico, jurídico e fiscal para criar programas culturais com uma abordagem omnichannel garantindo o alinhamento das ações, projetos proprietários ou patrocinados, com a estratégia de comunicação da marca.'
    },
    {
      title: 'Data-led Decision',
      text: 'Análise de dados continua, de dentro e de fora do negócio, para fornecer inteligência relevante e útil de forma a sustentar a estratégia e planejamento da marca através de ferramentas e abordagens, segmentação de patrocínio e modelagem de ROI, suporte analítico que ultrapassa os desafios intrínsecos da medição de patrocínio para apoiar decisões importantes.'
    },
    {
      title: 'Sponsorship Management System',
      text: 'Gerenciamento de patrocínios através da plataforma integrada de gestão exclusiva que simplifica o recebimento e análise de propostas, tangibilizando métricas, alcance e frequência, pontos de contato, segmentação de patrocínio, territórios, prestação de contas, etc.'
    }
  ]
}