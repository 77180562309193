





































































import { Component, Vue } from "vue-property-decorator";
import flow from '../assets/content/flow';
import hwheel from '../components/svg/halfwheel.vue';
import fwheel from '../components/svg/fourthwheel.vue';
import organic from '../components/svg/organic.vue';
import waves from '../components/svg/waves.vue';
import dots from '../components/svg/dots.vue';
import circles from '../components/svg/circles.vue';
import puzzle from '../components/svg/puzzle.vue';
import FlowContentStandalone from "@/components/FlowContentStandalone.vue";
import FlowContentMobile from "@/components/FlowContentMobile.vue";
import FlowExperienceStandalone from '@/components/FlowExperienceStandalone.vue';
import FlowExperienceMobile from '@/components/FlowExperienceMobile.vue';


@Component({
  components: {
    hwheel,
    fwheel,
    organic,
    waves,
    dots,
    circles,
    puzzle,
    FlowContentStandalone,
    FlowContentMobile,
    FlowExperienceStandalone,
    FlowExperienceMobile
  },
})
export default class Flow extends Vue {
  private get grid() {
    return flow.grid;
  }

  private get staticg() {
    return flow.static;
  }

  private windowWidth = 0

  private UpdateWindowWidth() {
    this.windowWidth = window.innerWidth;
  }

  mounted() {
    window.removeEventListener('resize', this.UpdateWindowWidth)
    window.addEventListener('resize', this.UpdateWindowWidth)
    this.UpdateWindowWidth();
  }
}
