











import { Component, Vue, Watch } from 'vue-property-decorator';
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';

@Component({
  components: {
    NavBar,
    Footer
  },
})

export default class App extends Vue {
  @Watch('$route')
    onRouteChange(current: any, previous: any) {
      if (current.name !== previous.name) {
        
        document.getElementById("app")?.scroll({
          behavior: 'auto',
          top: 0
        })
        document.getElementById("content")?.scroll({
          behavior: 'auto',
          top: 0
        })
      }
    }
}
