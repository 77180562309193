






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class HalfWheelShape extends Vue {
  @Prop({ default: 'orange' }) color!: string;
}
