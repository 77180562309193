

































































import { Component, Vue } from "vue-property-decorator";
import people from "@/assets/content/people";
import organic from '../components/svg/organic.vue';
import hwheel from '../components/svg/halfwheel.vue';
import circles from '../components/svg/circles.vue';
import dots from '../components/svg/dots.vue';
import waves from '../components/svg/waves.vue';

@Component({
  components: {
    organic,
    hwheel,
    circles,
    dots,
    waves
  },
})
export default class DNA extends Vue {
  private get team() {
    return people;
  }

  private getPic(prefix: string) {
    return require(`@/assets/img/people/${prefix}.png`);
  }
}
