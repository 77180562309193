import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import DNA from '../views/DNA.vue'
import Projetos from '../views/Projetos.vue'
import Projeto from '../views/Projeto.vue'
import Flow from '../views/Flow.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', name: 'Home', component: Home, meta: { title: 'Rompecabezas' } },
  { path: '/dna', name: 'DNA', component: DNA, meta: { title: 'Rompecabezas – DNA' } },
  { path: '/flow', name: 'Flow', component: Flow, meta: { title: 'Rompecabezas – Como fazemos' } },
  { path: '/projetos', name: 'Projetos', component: Projetos, meta: { title: 'Rompecabezas – Projetos' } },
  { path: '/projeto/:name', name: 'Projeto', component: Projeto }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
