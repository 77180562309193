

















































import { Component, Vue } from 'vue-property-decorator';
import organic from '../components/svg/organic.vue';
import hwheel from '../components/svg/halfwheel.vue';
import fwheel from '../components/svg/fourthwheel.vue';
import dots from '../components/svg/dots.vue';
import waves from '../components/svg/waves.vue';

@Component({
  components: {
    organic,
    hwheel,
    fwheel,
    dots,
    waves
  },
})
export default class Home extends Vue {
  private clients = [
    {
      name: 'Renner',
      logo: 'renner.png',
      tag: 'renner'
    },
    {
      name: 'Brastemp',
      logo: 'brastemp.png',
      tag: 'brast'
    },
    {
      name: 'Pepsi',
      logo: 'pepsi.png',
      tag: 'pepsi'
    },
    {
      name: 'Vivo',
      logo: 'vivo.png',
      tag: 'vivo'
    },
    {
      name: 'Michelin',
      logo: 'michelin.png',
      tag: 'michelin'
    },
    {
      name: 'Coca-Cola',
      logo: 'coca.png',
      tag: 'cocacola'
    },
    {
      name: 'Claro',
      logo: 'claro.png',
      tag: 'claro'
    },
    {
      name: 'Melitta',
      logo: 'melitta.png',
      tag: 'melitta'
    },
    {
      name: 'Danone',
      logo: 'danone.png',
      tag: 'danone'
    },
    {
      name: 'Brahma',
      logo: 'brahma.png',
      tag: 'brahma'
    },
    {
      name: 'Sherwin Williams',
      logo: 'sherwin.png',
      tag: 'sherwin'
    },
    {
      name: 'Ultra',
      logo: 'ultra.png',
      tag: 'ultra'
    },
    {
      name: 'Banrisul',
      logo: 'banrisul.png',
      tag: 'banri'
    },
    {
      name: 'Santander',
      logo: 'santander.png',
      tag: 'santander'
    },
    {
      name: 'Iguatemi',
      logo: 'iguatemi.png',
      tag: 'iguatemi'
    },
    {
      name: 'Multiplan',
      logo: 'multiplan.png',
      tag: 'multi'
    },
    {
      name: 'Zaffari',
      logo: 'zaffari.png',
      tag: 'zaffari'
    },
    {
      name: 'Ancar Ivanhoe',
      logo: 'ancar.png',
      tag: 'ancar'
    },
    {
      name: 'Grupo Soifer',
      logo: 'soifer.png',
      tag: 'soifer'
    },
  ]

  private carouselContent = [
    {
      title: 'Wineyard',
      place: 'Morumbi Shopping',
      img: 'thumb_1.png'
    },
    {
      title: 'Animaliê',
      place: 'Morumbi Shopping',
      img: 'thumb_2.png'
    },
    {
      title: 'Noite dos Museus',
      place: 'Porto Alegre',
      img: 'thumb_3.png'
    },
    
  ]

  private get carousel() {
    return this.$refs.carousel as HTMLElement
  }

  private get carouselItems() {
    return this.carousel.children;
  }
/*
  private easing = 'cubic-bezier(.435,.005,.07,1.005)';
  private frameDuration = 5000;

  private itemIndex = 0;

  private autoScroll() {
    const itemWidth = (this.carouselItems[0] as HTMLElement).getBoundingClientRect().width;
    setInterval(() => {
      (this.carouselItems[this.itemIndex] as HTMLElement).classList.remove('center');
      this.itemIndex += 1;
      if (this.itemIndex >= this.carouselItems.length) {
        this.itemIndex = 0;
        this.carousel.parentElement?.scrollTo({
          left: 0,
          behavior: 'smooth'
        })
      } else {
        this.carousel.parentElement?.scrollBy({
          left: itemWidth,
          behavior: 'smooth'
        });
      }
      (this.carouselItems[this.itemIndex] as HTMLElement).classList.add('center');
    }, this.frameDuration);
  }
*/
  private itemWidth = 0;
  private startTime = 0;
  private startPosition = 0;
  private finalPosition = 0;
  private duration = 500;
  private centerIndex = 0;
  private interval = 2000;

  private startScroll() {
    setTimeout(() => {
      (this.carouselItems[this.centerIndex] as HTMLElement).classList.remove('center');

      this.startTime = new Date().getTime();
      this.itemWidth = (this.carouselItems[0] as HTMLElement).getBoundingClientRect().width + 36;

      this.centerIndex++;

      if(this.centerIndex >= this.carouselItems.length) {
        this.startPosition = this.finalPosition
        this.finalPosition = 0;   

        this.centerIndex = 0;     
      } else {
        this.startPosition = (this.centerIndex - 1) * this.itemWidth;
        this.finalPosition = this.centerIndex * this.itemWidth;
      }

      this.scrollAnimation();

      (this.carouselItems[this.centerIndex] as HTMLElement).classList.add('center');

    }, this.interval);
  }

  private scrollAnimation() {
    const now = new Date().getTime();

    const elapsedTime = (now - this.startTime);

    const interpolation = elapsedTime / this.duration;

    let difference = (this.finalPosition - this.startPosition) * interpolation;
    if(difference > this.itemWidth) {
      difference = this.itemWidth
    }

    const currentPosition = this.startPosition + difference;

    this.carousel.parentElement?.scrollTo(currentPosition, 0)
    
    if(elapsedTime >= this.duration) {
      this.startScroll();
    } else {
      requestAnimationFrame(this.scrollAnimation);
    }
  }

  mounted() {
    this.startScroll()
    // this.autoScroll();
  }
}
