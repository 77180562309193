export default {
  0: 'Branded Content',
  1: 'Brand Entertainment',
  2: 'Brand Immersive Experiences',
  3: 'Branded Content Strategy',
  4: 'Intelligence Content',
  5: 'Brand & Retail Design Experience',
  6: 'Branded Content Studio',
  7: 'Influencer Network Strategy',
  8: 'Brand Activation',
  9: 'Branded Activation'
}