







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class DualCircleShape extends Vue {
  @Prop({ default: 'orange' }) colorPrimary!: string;
  @Prop({ default: 'teal'}) colorSecondary!: string;
}
