import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

const DEFAULT_TITLE = 'Rompecabezas — immersive experiences';
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngleLeft, faAngleRight, faAngleDown, faArrowUp, faExpand, faCompress, faArrowAltCircleUp, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebookSquare, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

library.add(faAngleLeft, faAngleRight, faAngleDown, faArrowUp, faExpand, faCompress, faArrowAltCircleUp, faPlus, faMinus);
library.add(faTwitter, faFacebookSquare, faInstagram, faLinkedinIn);
Vue.component('fa', FontAwesomeIcon);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
