


























import { Component, Vue } from "vue-property-decorator";
import projetos from "../assets/content/projetos";
import circles from '../components/svg/circles.vue';
import fwheel from '../components/svg/fourthwheel.vue';
import hwheel from '../components/svg/halfwheel.vue';
import dots from '../components/svg/dots.vue';


@Component({
  components: {
    circles,
    fwheel,
    hwheel,
    dots
  },
  methods: {
    redirect(route) {
      this.$router.push(route)
    }
  },
})
export default class Projetos extends Vue {

  private get projects() {
    return projetos;
  }
}
