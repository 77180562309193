
































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Home extends Vue {
    private scrollTop() {
        const options = {
            top: 0,
            behavior: 'smooth'
        };
        document.getElementById('app')?.scrollTo(options as ScrollToOptions);
        document.getElementById('content')?.scrollTo(options as ScrollToOptions);
    }
}
