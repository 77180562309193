import { render, staticRenderFns } from "./waves.vue?vue&type=template&id=778cc72d&scoped=true&"
import script from "./waves.vue?vue&type=script&lang=ts&"
export * from "./waves.vue?vue&type=script&lang=ts&"
import style0 from "./waves.vue?vue&type=style&index=0&id=778cc72d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778cc72d",
  null
  
)

export default component.exports